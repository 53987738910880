<template>
    <v-container fluid class="pa-0 pa-md-3">
		<v-card class="elevation-0">
			<QDataTableHeader color="q_leads_1" :id="entity + '_datatable'">
				<template #left>
					<div id="q-pending-policy-step-3">
						<div class="d-flex align-center">
							<p class="q-display-3 pb-0 pt-0 mt-0 mb-0 white--text">{{ title }}</p>
							<v-btn class="ml-1" dark icon @click="refreshData(true)">
								<v-icon>fas fa-redo-alt</v-icon>
							</v-btn>
						</div>
					</div>
				</template>

				<template #right>
					<v-spacer />
					<v-text-field
						v-model="search"
						:disabled="isDemoMode"
						dense
						dark
						append-icon="icon-q-search"
						label="Client Name or Policy #"
						single-line
						hide-details
						@keyup="maybeSearch"
						@click:append="refreshData"
					/>
				</template>
			</QDataTableHeader>

			<div class="data-table-actions">
				<v-btn text small color="q_leads_4" @click="toggleFilters">
					<v-icon small left>fas fa-filter</v-icon> Filters
				</v-btn>
				<v-btn :disabled="isLoading || !responseData.length" text small color="q_leads_4" @click="downloadCsvData">
					<v-icon small left>fas fa-file-export</v-icon> Export Results
				</v-btn>
			</div>

			<div class="data-table-container__row">
				<div class="data-table-container__col data-table-col">
					<QExpandableDataTable
						:items-per-page.sync="rows"
						:headers="allHeaders"
						:visibleHeaders="visibleHeaders"
						:items="all_data"
						:footer-props="{ itemsPerPageOptions: [10, 25, 50, 100] }"
						:loading="isLoading"
						:options.sync="options"
						:server-items-length="totalServerItems"
						item-key="ID"
						color="q_leads_1"
						class="elevation-0"
						fluid
						:allow-expand-key="allowExpandKey"
					>
						<template #item.AgentOnPolicy="{ item }">
							<router-link v-if="item.AgentCode" class="q_leads_4--text" :to="'/agents/' + item.AgentCode">{{ item.AgentOnPolicy }}</router-link>
							<span v-else>{{ item.AgentOnPolicy }}</span>
						</template>
						<template #item.PolicyStatus="{ item }">
							<div class="flex items-center gap-2">
								<!-- <a @click.stop="showStatusModal(item)">{{ item.PolicyStatus }}</a> -->
								<span>{{ item.PolicyStatus }}</span>
								<v-badge v-if="item.IncompletePolicyRequirementsCount" :content="item.IncompletePolicyRequirementsCount" color="red" inline />
							</div>
						</template>
						<template #expand="{ item }">
							<v-simple-table>
								<thead>
									<tr class="font-weight-bold">
										<td>Requested Date</td>
										<td>Received Date</td>
										<td>Requirements</td>
									</tr>
								</thead>
								<tbody>
									<tr v-for="requirement in item.PolicyRequirements" :key="requirement.id">
										<td>{{ requirement.RequestedDate }}</td>
										<td>
											<span v-if="requirement.RequirementComplete === REQUIREMENT_STATUS.COMPLETE && requirement.ReceivedDate">{{ requirement.ReceivedDate }}</span>
										</td>
										<td>
											<v-row align="center">
												<v-icon v-if="requirement.RequirementComplete === REQUIREMENT_STATUS.COMPLETE" color="green" small left>fas fa-check</v-icon>
												<v-badge v-else content=" " color="red" inline />
												<span>{{ requirement.Description }}</span>
											</v-row>
										</td>
									</tr>
									<tr v-if="!item.PolicyRequirements || !item.PolicyRequirements.length">
										<td class="px-4">No data.</td>
									</tr>
								</tbody>
							</v-simple-table>
						</template>
						<template v-slot:body.append>
							<tr>
								<th colspan="2" class="title">
									<strong>Grand Total</strong>
									<strong v-if="visibleHeaders.length < 3" class="ml-3">{{ computedGrandTotal }}</strong>
								</th>
								<th v-if="visibleHeaders.length > 4" :colspan="2"></th>
								<th v-if="visibleHeaders.length > 2" class="title">
									<strong>{{ computedGrandTotal }}</strong>
								</th>
								<th v-if="visibleHeaders.length > 5" colspan="5"></th>
							</tr>
						</template>
					</QExpandableDataTable>
				</div>

				<div v-show="showFilters" class="data-table-container__col filters-col">
					<QFilters
						ref="filters"
						v-model="filters"
						:keys="filter_keys"
						:available-filters="available_filters"
						:filters-details="filtersDetails"
						hide-button dark column
					>
						<template #before>
							<v-col cols="12">
								<QDatePeriodFilter v-model="datePeriod" :periods="datePeriodsConfig" />
							</v-col>
						</template>
						<!-- <v-col cols="12">
							<QAgentLookup
								v-if="hasDownlineAgents"
								v-model="selectedDownlineAgent"
								:filters="{'Status' : 'Active,Available,Lapsed,Stalled,Terminated'}"
								label="Agent"
								return-object
							/>
						</v-col> -->
						<v-col cols="12">
							<QPolicyTableStatFilter
								v-model="statType"
								:agent="selectedDownlineAgent || agent || undefined"
							/>
						</v-col>
					</QFilters>
				</div>
			</div>
		</v-card>

		<v-dialog v-if="selectedStatusItem" v-model="dialog" width="400">
            <v-card class="pa-6 text-center" min-height="">
                <v-card-title class="justify-center">
                    <h4>{{ selectedStatusItem.PolicyStatus }}</h4>
                </v-card-title>
                <p>Lorem ipsum ...</p>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import moment from 'moment'
import { debounce } from 'vue-debounce'

import QuilityAPI from '@/store/API/QuilityAPI.js'
import QDataTableMixin from './QDataTableMixin.js'
import QDataTableHeader from './base/QDataTableHeader.vue'
import QExpandableDataTable from './QExpandableDataTable.vue'
// import QAgentLookup from '@/components/utils/QAgentLookup.vue'
import QPolicyTableStatFilter from '@/components/datatables/QPolicyTableStatFilter.vue'
import QDatePeriodFilter from '@/components/utils/QDatePeriodFilter.vue'

const allHeaders = [
    {
        text: 'Submitted Date',
        value: 'SubmittedDate',
        align: 'start',
        sortable: true,
        filterable: true
    },
    {
        text: 'Agent',
        value: 'AgentOnPolicy',
        align: 'start',
        sortable: true,
        filterable: true
    },
	{
        text: 'Policy #',
        value: 'PolicyNumber',
        align: 'start',
        sortable: true,
        filterable: true
    },
    {
        text: 'Client',
        value: 'InsuredName',
        align: 'start',
        sortable: true,
        filterable: true,
        class: 'header-class',
    },
    {
        text: 'Face Amount',
        value: 'FaceAmount',
        align: 'start',
        sortable: true,
        filterable: true
    },
    {
        text: 'APV',
        value: 'AnnualPremium',
        align: 'start',
        sortable: true,
        filterable: true
    },
    {
        text: 'Carrier',
        value: 'OrigCarrier',
        align: 'start',
        sortable: true,
        filterable: true,
        class: 'header-class',
    },
    {
        text: 'Product',
        value: 'Product',
        align: 'start',
        sortable: true,
        filterable: true
    },
    {
        text: 'Status',
        value: 'PolicyStatus',
        align: 'start',
        sortable: true,
        filterable: true,
    },
]

const REQUIREMENT_STATUS = {
    INCOMPLETE: '0',
    COMPLETE: '1',
}

const ALL_OPTION = '-All-'

const getStatDefault = (forBaseshop) => forBaseshop ? 'Baseshop' : 'PersonalProduction'

const datePeriodDefault = { // last 6 months
	startDate: moment().subtract(5, 'months').startOf('month').format('YYYY-MM-DD'),
	endDate: moment().format('YYYY-MM-DD'),
}

export default {
    name: 'QPendingPolicyDataTable',
	components: {
        QDataTableHeader,
        QExpandableDataTable,
        // QAgentLookup,
		QPolicyTableStatFilter,
		QDatePeriodFilter,
    },
    mixins: [QDataTableMixin],
    props: {
		agent: Object,
		for_baseshop: { type: [Boolean, Number] },
	},
    data () {
		const iStatType = getStatDefault(this.for_baseshop)
        return {
            dataLoading: false,
            entity: 'pendingpolicy',
            //use this to "preset" a filter
            filters: {},
            firstPull: true, // this keeps the auto scrolling on data reloads from happening on the first load.
            responseData: [],
            totalServerItems: 0,
            filtersOptions: null,
            options: {
				sortBy: ['SubmittedDate'],
				sortDesc: [true],
			},
            selectedStatusItem: null,
            allHeaders,
            REQUIREMENT_STATUS,
            allowExpandKey: 'expandable',
            selectedDownlineAgent: null,
			datePeriod: { ...datePeriodDefault },
			datePeriodsConfig: [
				{ fn: 'lastDays', period: 7 },
				{ fn: 'lastDays', period: 30 },
				{ fn: 'lastDays', period: 60 },
				{ fn: 'lastDays', period: 90 },
				{ fn: 'lastMonths', period: 6 },
				{ fn: 'ytd' },
				{ fn: 'all' },
			],
			statType: iStatType, // TotalAgency | Baseshop | PersonalProduction
			grandTotal: 0,
        }
    },
    created: function() {
        this.refreshData();
    },
    computed: {
        filter_keys: function() {
            // these should match up with the keys for the model on the server side - availableFilters
            return ["Carrier", "PolicyStatus"]
        },
        all_data: function() {
            //instead of using a state/model on the frontend the data is all contained in this module.
            //this computed property is required by the QDataTableMixin
            return this.responseData.map((item) => {
                const incompletePolicyRequirements = item.PolicyRequirements
                    ? item.PolicyRequirements.filter(({ RequirementComplete }) => RequirementComplete === REQUIREMENT_STATUS.INCOMPLETE)
                    : []

                return {
                    ...item,
                    FaceAmount: item.FaceAmount ? this.formatCurrency(item.FaceAmount) : 'NA',
                    AnnualPremium: item.AnnualPremium ? this.formatCurrency(item.AnnualPremium) : 'NA',
                    SubmittedDate: item.SubmittedDate ? this.formatDate(item.SubmittedDate) : 'NA',
                    OrigCarrier: item.Carrier,
                    IncompletePolicyRequirementsCount: incompletePolicyRequirements.length,
					[this.allowExpandKey]: !!item.PolicyRequirements?.length,
                }
            })
        },
        visibleHeaders() {
            if (this.$vuetify.breakpoint.smAndDown) {
                return allHeaders.slice(0, 2)
            }

            if (this.$vuetify.breakpoint.mdAndDown) {
                return allHeaders.slice(0, 5)
            }

            return allHeaders.slice(0, 9)
        },
        carrierSelected() {
            return this.filters?.Carrier && this.filters?.Carrier !== ALL_OPTION
        },
        // filtersOptions will be set by our first data pull where the server responds with all data for the filter drop downs.
        // QDataTableMixin requires this computed property.
        available_filters() {
            if (!this.filtersOptions) {
                return this.filtersOptions
            }

            if (!this.carrierSelected) {
                return this.filtersOptions
            }

            const carrier = this.filtersOptions.Carrier.find(item => item.value === this.filters?.Carrier)
            return {
                ...this.filtersOptions,
                PolicyStatus: [ALL_OPTION, ...(carrier?.statuses || [])],
            }
        },
        isLoading: function() {
            return this.dataLoading
        },
        filtersDetails() {
            return this.carrierSelected
                ? []
                : [
                    {
                        key: "PolicyStatus",
                        details: {
                            disabled: true,
                            "error-messages": "You must choose a carrier before you can select a status",
                        },
                    },
                ]
        },
        hasAgencyOwnerPermissions() {
            return this.user.Agent.IsAgencyOwner || this.user.Agent.HasManagerAccess
        },
        // hasDownlineAgents () {
		// 	return this.user.Agent.HasManagerAccess // agent has a downline
        // },
		agentCode () {
			if (this.selectedDownlineAgent) { return this.selectedDownlineAgent.AgentCode }
			return this.agent ? this.agent.AgentCode : this.user.Agent.AgentCode
		},
		computedGrandTotal() {
            return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(this.grandTotal)
		},
    },
    watch: {
        agentCode () {
            this.options.page = 1
			this.refreshData()
        },
		statType () {
			this.options.page = 1
			this.refreshData()
		},
		datePeriod: {
			deep: true,
			handler () {
				this.options.page = 1
				this.refreshData()
			},
		},
		showFilters (val) {
			if (!val) {
				this.setDateFilterDefaultValue()
				this.setStatFilterDefaultValue()
				// this.resetAgentLookup()
			}
		},
        'filters.Carrier' () {
            this.filters.PolicyStatus = ALL_OPTION
        },
    },
    methods: {
        loadData: debounce(async function(options) {
			const filters = {
				...this.filters,
				...this.datePeriod,
				statType: this.statType,
			}
            this.dataLoading = true
            await QuilityAPI.getAgentPendingPolicies(this.agentCode, filters, options)
                .then((json) => {
                    this.setResponseData(json)
					this.grandTotal = json.grandTotal;
					this.dataLoading = false
                })
				.catch((err) => {
					if (err.message === 'Aborted') { return }
                    console.log(err)
					this.showError('Whoops! There was a problem loading Pending Policies.<br>', err)
                })
            this.firstPull = false
        }, 200),
        setResponseData(response) {
            this.responseData = response.data
            this.totalServerItems = response.meta?.total
            if (response.meta?.filters) {
                this.filtersOptions = response.meta.filters
            }
        },
        showStatusModal(item) {
            this.selectedStatusItem = item
            this.dialog = true
        },
        downloadCsvData: debounce(function() {
            const params = {
                ...this.options,
                ...this.filters,
				...this.datePeriod,
                sortBy: this.options.sortBy?.[0],
                sortDesc: (this.options.sortDesc?.length ? (this.options.sortDesc[0] ? 'true' : 'false') : null),
                search: this.search,
				statType: this.statType,
                attr: '',
                itemsPerPage: 'csv',
            }
            const path = `/api/private/new_policies/pending/agent/${this.agentCode}`
            this.dispatchJob({
                method: 'GET',
                path,
                params,
                JobName: 'Pending Policies Export',
            })
        }, 200),
		setDateFilterDefaultValue () {
			this.datePeriod.startDate = datePeriodDefault.startDate
			this.datePeriod.endDate = datePeriodDefault.endDate
		},
		setStatFilterDefaultValue () {
			this.statType = getStatDefault(this.for_baseshop)
		},
		// resetAgentLookup () {
		// 	this.selectedDownlineAgent = null
		// },
    },
}
</script>

<style lang="scss" scoped>
.nowrap {
    white-space: nowrap;
}

.data-table-container__row {
	width: 100%;
	display: flex;
	flex-wrap: wrap-reverse;
	gap: 1rem 3rem;

	.data-table-container__col {
		max-width: 100%;
	}

	.data-table-col {
		flex: 10 10 600px;
		overflow-x: auto;
		padding: 12px;
	}

	.filters-col {
		flex: 1 0 280px;
	}
}

.data-table-actions {
	margin: 1rem 2rem;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	gap: 1rem 2rem;
}
</style>
