<template>
	<v-radio-group v-if="doShow" v-model="mValue" :disabled="disabled || loading" hide-details class="mt-2">
		<v-radio
			v-for="option in options" :key="option.value"
			:value="option.value"
			:disabled="option.disabled"
			:color="color"
		>
			<template #label>
				<span>{{ option.label }}</span>
				<v-tooltip v-if="option.tooltip" top>
					<template v-slot:activator="{ on, attrs }">
						<v-icon :color="color" v-bind="attrs" v-on="on">icon-info-circle-q</v-icon>
					</template>
					<div v-html="option.tooltip" style="max-width: 25rem" />
				</v-tooltip>
			</template>
		</v-radio>
	</v-radio-group>
</template>

<script>
import QuilityAPI from '@/store/API/QuilityAPI'
import Level from '@/store/Models/AgentManagement/Level'
import { statTypes } from './statTypes'

const leadershipLevelsDefaults = [
    'Managing Partner',
    'Senior Partner',
    'Associate Partner',
    'Executive Vice President',
    'Senior Vice President',
    'Managing Vice President',
    'Regional Agency Director',
    'Agency Director',
    'Agency Owner',
    'Key Leader',
    'Team Leader',
    'Sales Representative',
    'SFG',
    'Asurea',
    'Wholesale Agency',
    'Tenant',
    'Quility',
]

const defaultOptions = [
	{
		label: 'Baseshop',
		value: statTypes.baseshop,
		disabled: false,
		tooltip: '<strong>Baseshop</strong>: This filter includes applications submitted personally by the leader and their immediate group of downline agents, excluding any downlines that broke out as Agency Owners.',
	},
	{
		label: 'Personal',
		value: statTypes.personal,
		disabled: false,
		tooltip: '<strong>Personal</strong>: This filter only includes the agent\'s personally submitted applications.',
	},
	{
		label: 'Master Agency',
		value: statTypes.masterAgency,
		disabled: false,
		tooltip: '<strong>Master Agency</strong>: This filter (for Equity Partners) includes applications submitted personally by the leader and all downline agents under them, excluding 130 breakout agencies.',
	},
	{
		label: 'Total Agency',
		value: statTypes.totalAgency,
		disabled: false,
		tooltip: '<strong>Total Agency</strong>: This filter includes applications submitted from personal production and all downline agents, regardless of level of leadership breakout agencies.',
	},
]

export default {
    props: {
		value: {
			type: String,
			default: statTypes.personal,
		},
		title: {
			type: String,
			default: 'Agency',
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		withMasterAgency: {
			type: Boolean,
			default: false,
		},
		color: {
			type: String,
			default: 'primary',
		},
		agent: { type: Object },
	},
	data () {
		return {
			levelsLoading: false,
			agentDetailsLoading: false,
			hasEquityPartnerInd: false,
		}
	},
	created () {
		this.getLevels()
		if (this.withMasterAgency) {
			this.getAgentDetails()
		}
	},
    computed: {
		mValue: {
			get () { return this.value },
			set (val) { this.$emit('input', val) },
		},
		loading () {
			return this.levelsLoading || this.agentDetailsLoading
		},
		leadershipLevels () {
			const levels = Level.query().where('LevelType', 'Leadership').get()
			if (!levels?.length) { return leadershipLevelsDefaults }
			return levels
				.sort((a, b) => b.LevelRank - a.LevelRank)
				.map(({ LevelName }) => LevelName)
		},
		mAgent () {
			return this.agent || this.user.Agent
		},
		leadershipLevel () {
			return this.mAgent.LeadershipLevel
		},
		leadershipOrderRelativeToAO () {
			const AOIndex = this.leadershipLevels.indexOf('Agency Owner')
			const agentLLIndex = this.leadershipLevels.indexOf(this.leadershipLevel)
			if (agentLLIndex === AOIndex) { return 0 }
			return agentLLIndex > AOIndex ? -1 : 1
		},
		initialOptions () {
			if (this.withMasterAgency) { return defaultOptions }
			return defaultOptions.filter(({ value }) => value !== statTypes.masterAgency)
		},
		hasAdminPermissions () {
			return this.hasRole(['SuperAdmin', 'Staff'])
		},
		options () {
			if (this.hasAdminPermissions && this.mAgent.AgentCode === this.user.Agent.AgentCode) {
				return this.initialOptions
			}
			return this.initialOptions.map((option) => {
				const disabledTotalAgency = option.value === statTypes.totalAgency && this.leadershipOrderRelativeToAO === 0
				const disabledBaseshop = option.value === statTypes.baseshop && this.leadershipOrderRelativeToAO < 0
				const disabledMasterAgency = option.value === statTypes.masterAgency && !this.hasEquityPartnerInd
				const disabled = disabledTotalAgency || disabledBaseshop || disabledMasterAgency
				return { ...option, disabled }
			})
		},
		doShow () {
			return this.options.length > 1
		},
    },
	watch: {
		options: {
			deep: true,
			handler () {
				const optionDisabled = this.options.find(({ value }) => value === this.value).disabled
				if (optionDisabled) {
					this.mValue = statTypes.personal
				}
			},
		},
		agent () {
			if (this.withMasterAgency) {
				this.getAgentDetails()
			}
		},
	},
	methods: {
		async getLevels () {
			this.levelsLoading = true
			try {
				await QuilityAPI.getAgentManagement_Levels()
			} catch (err) {
				console.error(err)
				// this.showError('Oops! There was a problem loading Leadership Levels.<br>', err.message)
			} finally {
				this.levelsLoading = false
			}
        },
		async getAgentDetails () {
			this.agentDetailsLoading = true
			try {
				const agent = await QuilityAPI.getAgentStateless(this.mAgent.AgentCode, ['EquityPartnerInd'])
				this.hasEquityPartnerInd = !!agent.EquityPartnerInd
			} catch (err) {
				console.error(err)
				this.showError('Oops! There was a problem loading agent details.<br>', err.message)
			} finally {
				this.agentDetailsLoading = false
			}
        },
	},
}
</script>
